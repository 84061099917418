import { Injectable } from '@angular/core';
import { UserService } from '@app/services/user.service';
import { Router } from '@angular/router';
import { AppConstant } from '@app/app.constant';
import { NotificationService } from '@app/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  /**
   * Check status conectivity
   */
  checkStatus() {
    this.userService
      .doesConnectionExist()
      .then((status: any) => {
        console.log('status', status);
        if (status) {
          this.notificationService.error(AppConstant.SERVER_ERROR);
        }
      })
      .catch(err => {
        this.notificationService.error(AppConstant.NO_INTERNET_CONNECTION);
      });
  }

  /**
   * Display Error message
   * @param errorResponse
   */
  displayErrorMessage(errorResponse) {
    if (errorResponse && errorResponse.message && errorResponse.error) {
      const errorCode = errorResponse.code;
      if (errorResponse.error.error && errorResponse.error.error) {
        this.notificationService.error(errorResponse.error.error.message);
      }
      if (
        errorCode === -101 ||
        errorCode === '-101' ||
        errorCode === -145 ||
        errorCode === '-145'
      ) {
        localStorage.clear();
        this.router.navigate(['sign-in']);
      }
    } else {
      this.checkStatus();
    }
  }
}
