import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
declare const $: any;

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  baseURL: string = environment.baseURL;
  header: any;

  constructor(private http: HttpClient) {}

  /**
   * sets the API request header
   */
  createHeader() {
    const token = JSON.parse(localStorage.getItem('KIIN-token'));
    return (this.header = new HttpHeaders({
      apikey: environment.apikey,
      'Content-Type': 'application/json',
      token: token ? token : ''
    }));
  }

  /*
    * User sign-ip API
    * @param loginData
    * */
  signIn(loginData): Observable<any> {
    return this.http.post(this.baseURL + 'users/login', loginData, {
      headers: this.createHeader()
    });
  }

  /*
    * Ajax call to check the internet connection
    * */
  doesConnectionExist() {
    return new Promise((resolve, reject) => {
      const randomNum = Math.round(Math.random() * 10000);
      $.ajax({
        type: 'GET',
        url: `https://www.google.com?rand=${randomNum}`,
        dataType: 'jsonp',
        timeout: 3000,
        success() {
          resolve(true);
        },
        error(XMLHttpRequest, textStatus, errorThrown) {
          if (textStatus === 'timeout' || textStatus === 'error') {
            reject(false);
          } else if (XMLHttpRequest.status < 304 || textStatus === 'load') {
            resolve(true);
          } else {
            resolve(true);
          }
        }
      });
    });
  }

  /*
    * User fetch users API
    * @param pagination Data
    * */
  getAllUsersList(data): Observable<any> {
    // return this.http.get(this.baseURL + 'users/fetchUsers', {
    //   headers: this.createHeader()
    // });
    const URL =
      this.baseURL +
      'users/fetchUsers' +
      '?status=' +
      data.status +
      '&page_no=' +
      data.page_no +
      '&limit=' +
      data.limit;
    return this.http.get(URL, {
      headers: this.createHeader()
    });
  }

  /*
    * User reset user password
    * @param user data
    * */
  getchangePassword(data): Observable<any> {
    const URL = this.baseURL + 'users/changePassword';
    return this.http.post(URL, data, {
      headers: this.createHeader()
    });
  }

  editUsersProfile(data): Observable<any> {
    const URL = this.baseURL + 'users/editUserProfile';
    return this.http.put(URL, data, {
      headers: this.createHeader()
    });
  }

  getProfileByID(data) {
    const URL = this.baseURL + 'users/fetchUsers/' + '?id=' + data.id;
    return this.http.get(URL, {
      headers: this.createHeader()
    });
  }
}
